import Swal from 'sweetalert2';

export const warningAlert = (title: string, text: string, button = 'Delete') => (
  Swal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: button,
    showClass: { popup: '' },
    hideClass: { popup: '' },
    iconColor: '#A40A00',
    customClass: {
      confirmButton: 'btn btn-primary me-1',
      cancelButton: 'btn btn-secondary ms-1',
    },
    buttonsStyling: false,
  })
);

export const successAlert = (title: string, text: string, button = 'OK') => (
  Swal.fire({
    title,
    text,
    icon: 'success',
    confirmButtonText: button,
    showClass: { popup: '' },
    hideClass: { popup: '' },
    iconColor: '#155724',
    customClass: {
      confirmButton: 'btn btn-primary me-1',
    },
    buttonsStyling: false,
  })
);

export const errorAlert = (title: string, text: string, button = 'OK') => (
  Swal.fire({
    title,
    text,
    icon: 'error',
    confirmButtonText: button,
    showClass: { popup: '' },
    hideClass: { popup: '' },
    iconColor: '#A40A00',
    customClass: {
      confirmButton: 'btn btn-primary me-1',
    },
    buttonsStyling: false,
  })
);

export const fatalError = (text: string, title = 'An error has occurred') => {
  const html = `<p>${text}</p>` +
    '<p>Please refresh and try again, or contact support if the problem continues</p>';

  Swal.fire({
    title,
    html,
    icon: 'error',
    confirmButtonText: 'Refresh',
  }).then(() => window.location.reload());
};
