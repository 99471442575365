import { Vocabs } from 'ui/types';
import { titleize, underscore } from 'inflection';

type VocabTypes =
'point' | 'planDesignPoint' | 'challenge' | 'todo' |
'location' | 'department' | 'job' | 'captain';

export const getVocab = (vocab: VocabTypes, vocabs: Vocabs): string => (
  vocabs[vocab] || titleize(underscore(vocab))
);

export const lowercaseWithAcronyms = (str: string): string => (
  str.split(' ').map(word => (word.toUpperCase() === word ? word : word.toLowerCase())).join(' ')
);
