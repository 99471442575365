import * as Images from 'ui/assets/categories';

export const categoryImage = (category: string) => {
  switch (category) {
    case 'balance': return Images.Balance;
    case 'community': return Images.Community;
    case 'financial': return Images.Financial;
    case 'mindfulness': return Images.Mindfulness;
    case 'nutrition': return Images.Nutrition;
    case 'physical': return Images.Physical;
    case 'purpose': return Images.Purpose;
    case 'social': return Images.Social;
    case 'post': return Images.Post;
    default: return Images.Other;
  }
};

export default categoryImage;
