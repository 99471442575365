import moment from 'moment';

export * from './alerts';
export * from './features';
export * from './vocabs';

export { default as apiClient } from './apiClient';
export { default as submitByMethod } from './submitByMethod';
export { default as hasPermission } from './permissions';
export { default as categoryImage } from './categoryImage';
export { default as fitnesses } from './fitnesses';

export { getErrorMessage } from './apiClient';

export const formatDate = (date: string): string => moment(date).format('MMMM D, YYYY');
